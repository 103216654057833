import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../ui/Input";

const RepassageProduction = () => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [value, setValue] = useState(0);
  const [totalSortie, setTotalSortie] = useState(0);
  const [encore, setEncore] = useState(0);
  const [valueRep , setValueRep] = useState(0);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await api.get("/models");
        setModels(response.data);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, []);

  useEffect(() => {
    const fetchRepassageProduction = async () => {
      if (selectedModel) {
        try {
          const response = await api.get(
            `/repassage_production/${selectedModel}`
          );
          calculateEncore(selectedModel, response.data.total_value);
          setValueRep(response.data.total_value);
        } catch (error) {
          calculateEncore(selectedModel, 0);
          console.error("Error fetching RepassageProduction data:", error);
        }
      }
    };

    fetchRepassageProduction();
  }, [selectedModel]);

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const handleSave = async () => {
    try {
      if (value > totalSortie) {
        toast.error("Total Repassage can't be more than the total sortie");
        return;
      }
  
      const response = await api.post(`/repassage_production/${selectedModel}`, {
        value: value,
      });
      calculateEncore(selectedModel, response.data.total_value);
      setValueRep(response.data.total_value)
      setValue(0);
      toast.success("Record saved successfully");
    } catch (error) {
      console.error("Error saving production data:", error);
    }
  };

  const handleValueChange = async (name , value) => {
    const newValue = value === "" ? 0 : parseInt(value, 10);

    if (newValue < 0) {
      toast.error("Value cannot be less than 0");
      return;
    }

    if (newValue > totalSortie) {
      toast.error("Total Repassage can't be more than the total sortie");
      return;
    }

    setValue(newValue);
  };


  const calculateEncore = async (modelId, repassageValue) => {
    try {
      const response = await api.get(`/production_chains/${modelId}/sortie`);
      const totalSortie = response.data.totalSortie;
      setTotalSortie(totalSortie);

      const encore = totalSortie - repassageValue;
      if (encore < 0) {
        toast.error("Encore cannot be less than 0");
        setEncore(0);
      } else {
        setEncore(encore);
      }
    } catch (error) {
      console.error("Error calculating encore:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await api.post(`/repassage_production_update/${selectedModel}`, {
        value: value,
      });
      calculateEncore(selectedModel, response.data.total_value);
      setValueRep(response.data.total_value)
      setValue(0);
      toast.success("Record saved successfully");
    } catch (error) {
      console.error("Error saving production data:", error);
    }
  }

  const handleReset = async () => {
    try {
      await api.delete(`/repassage_production/${selectedModel}`)
      const response = await api.get(`/production_chains/${selectedModel}/sortie`);
      const totalSortie = response.data.totalSortie;

      if (totalSortie == null) {
        setEncore(0);
      } else {
        setEncore(totalSortie);
      }
      setValueRep(0)
      setValue(0)
    } catch (error) {
      console.error("Error saving production data:", error);
    }
  }


  const selectedModelData = models.find((model) => model.id == selectedModel);

  return (
    <div className="ml-[19%] pt-[6rem]">
      <ToastContainer />
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Repassage</h2>
        <div className="ml-7 mb-4 pr-6">
          <select
            className="block w-full mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
            value={selectedModel}
            onChange={handleModelChange}
          >
            <option value="">Select model</option>
            {models.map((model) => (
              <option key={model.id} value={model.id}>
                {model.modele}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedModelData && (
        <div className="w-full pr-6">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold text-xl">
              {selectedModelData.category}
            </h3>
            <h3 className="font-semibold text-xl">
              {selectedModelData.client}
            </h3>
          </div>
          <div className="flex my-5 items-center justify-between">
            <h3 className="font-semibold text-xl">Encour: {encore}</h3>
            <h3 className="font-semibold text-xl">Total: {valueRep}</h3>
          </div>
          <button
            onClick={handleReset}
            className="mt-4 bg-red-500 text-white p-2 rounded"
          >
            Reset
          </button>
        </div>
      )}
      {selectedModel && (
       <div className="flex flex-col p-5 mt-[2rem] mb-4 shadow-md w-fit p-1 rounded border">
            <Input
              handleChange={handleValueChange}
              name={"repassage"}
              label="Total Repassage"
              text={value}
            />
            <div className='flex items-center justify-between'>
              <button
                onClick={handleSave}
                className="mt-4 w-[48%] bg-blue-500 text-white p-2 rounded"
              >
                Save
              </button>
              <button
                onClick={handleUpdate}
                className="mt-4 bg-yellow-400 text-white p-2 rounded w-[48%]"
                >
                Modifier
              </button>
            </div>
        </div>
      )}
    </div>
  );
};

export default RepassageProduction;
