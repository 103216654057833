import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import Input from "../ui/Input";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductionChainEntre = () => {
  // encour chain 
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [chains, setChains] = useState([]);
  const [selectedChain, setSelectedChain] = useState("");
  const [encour, setEncour] = useState(0);
  const [entre, setEntre] = useState(0);
  const [sortie, setSortie] = useState(0);
  const [total, setTotal] = useState(0);
  const [quantity, setQuantity] = useState(0)

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await api.get("/models");
        setModels(response.data);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    const fetchChains = async () => {
      try {
        const response = await api.get("/chains");
        setChains(response.data);
      } catch (error) {
        console.error("Error fetching chains:", error);
      }
    };

    fetchModels();
    fetchChains();
  }, []);

  const fetchProductPlan = async () => {
    if (selectedModel && selectedChain) {
      try {
        // Fetch product plan by model and chain
        const responsepl = await api.get(
          `/product_plans_model/${selectedModel}/${selectedChain}`
        );

        if (responsepl.data?.id) {
          // Fetch product plan details using the retrieved ID
          const responsepla = await api.get(
            `/product_plans_single/${responsepl.data.id}`
          );
          const fetchedData = responsepla.data;
          setQuantity(fetchedData.qte);
        }
      } catch (error) {
        setQuantity(0)
        console.error("Error fetching product plan:", error);
      }
    }
  };

  const fetchChainProductionSortie = async () => {
    if (selectedModel && selectedChain) {
      try {
        // Fetch chain production sortie data
        const response = await api.get(
          `/chain_production_sortie/${selectedModel}/${selectedChain}`
        );

        if (response.data) {
          const { entre, sortie } = response.data;

          setEncour(parseInt(entre) - parseInt(sortie));
          setSortie(sortie);
          setTotal(entre);
        }
      } catch (error) {
        setEncour(0);
        setSortie(0);
        setTotal(0);
        console.error("Error fetching chain production sortie:", error);
      }
    }
  };

  useEffect(() => {  
    // Call both functions in sequence or in parallel
    const fetchData = async () => {
      await fetchProductPlan();
      await fetchChainProductionSortie();
    };
  
    fetchData();
  }, [selectedModel, selectedChain]);
  
  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const handleChainChange = (e) => {
    setSelectedChain(e.target.value);
  };

  const onChange = (name, value) => {
    const newValue = value === "" ? 0 : parseInt(value, 10);

    if (isNaN(newValue)) {
      setEntre(0);
      return;
    }

    setEntre(newValue);
  };

  const handleSave = async () => {
    const selectedModelData = models.find((model) => model.id == selectedModel);
    const encourValue = (parseInt(total) + parseInt(entre))  - parseInt(sortie);
    console.log('encour:' + encour , 'entre:' + entre , 'sortie: ' + sortie)
    setTotal(parseInt(total) + parseInt(entre))

    try {
      await api.post(`/chain_production/${selectedModel}/${selectedChain}`, {
        entre: entre,
      });
      setEncour(encourValue);
      setEntre(0);
      toast.success("Record saved successfully");
    } catch (error) {
      console.error("Error saving production data:", error);
    }
  };


  const handleReset = async () => {
    try {
      await api.delete(`/chain_production/${selectedModel}/${selectedChain}`)
      setEncour(parseInt(total) + parseInt(encour))
      setEntre(0)
      setTotal(0)
    } catch (error) {
      console.error("Error saving production data:", error);
    }
  }

  const handleUpdate = async () => {
    try {
      await api.post(`/chain_production_update/${selectedModel}/${selectedChain}`, {
          entre: entre,
      });
 
      const fetchData = async () => {
        await fetchProductPlan();
        await fetchChainProductionSortie();
      };
    
      fetchData();
      
     
      setEntre(0);
      toast.success("Record updated successfully");
     } catch (error) {
      console.error("Error updating CoupeProduction data:", error);
     }
  }

  const selectedModelData = models.find((model) => model.id == selectedModel);

  return (
    <div className="ml-[19%] pt-[6rem]">
      <ToastContainer />
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Production Entre Chain</h2>
        <div className="ml-7 mb-4 pr-6 flex items-center w-[325px] justify-between">
          <select
            className="block w-fit mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
            value={selectedModel}
            onChange={handleModelChange}
          >
            <option value="">Select model</option>
            {models.map((model) => (
              <option key={model.id} value={model.id}>
                {model.modele}
              </option>
            ))}
          </select>
          <select
            className="block w-fit mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
            value={selectedChain}
            onChange={handleChainChange}
          >
            <option value="">Select Chain</option>
            {chains.map((chain, index) => (
              <option key={index} value={chain.id}>
                {chain.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedModelData && selectedChain && (
        <div className="w-full pr-6">
          <div
            key={selectedModelData.id}
            className="flex items-center justify-between"
          >
            <h3 className="font-semibold text-xl">
              {selectedModelData.category}
            </h3>
            <h3 className="font-semibold text-xl">
              {selectedModelData.client}
            </h3>
          </div>
          <div className="flex my-5 items-center justify-between">
            <h3 className="font-semibold text-xl">Encour: {encour}</h3>
            <h3 className="font-semibold text-xl">Total: {total}</h3>
          </div>
          <div className="flex my-5 items-center justify-between">
            <h3 className="font-semibold text-xl">Quantity: {quantity}</h3>
               <button
                onClick={handleReset}
                className="mt-4 bg-red-500 text-white p-2 rounded"
                >
                Reset
              </button>
          </div>
        </div>
      )}
      <div className="flex justify-between items-center">
        {selectedModel && selectedChain && (
          <div className="flex flex-col p-5 mt-[2rem] mb-4 shadow-md w-fit p-1 rounded border">
            <Input
              handleChange={onChange}
              name={"entre"}
              label="Total Entre"
              text={entre}
            />
            <div className="flex items-center justify-between">
               <button
                onClick={handleSave}
                className="mt-4 w-[48%] bg-blue-500 text-white p-2 rounded"
                >
                Save
              </button>
              <button
                onClick={handleUpdate}
                className="mt-4 w-[48%] bg-yellow-400 text-white p-2 rounded w-[48%]"
                >
                Modifier
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductionChainEntre;
