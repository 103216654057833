import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../ui/Input";

const MagasinProduction = () => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [production, setProduction] = useState(0);
  const [entre, setEntre] = useState(0);
  const [encore, setEncore] = useState(0);
  const [totalSortie, setTotalSortie] = useState(0);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await api.get("/models");
        setModels(response.data);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, []);

  useEffect(() => {
    const fetchMagasinProduction = async () => {
      if (selectedModel) {
        try {
          const response = await api.get(
            `/magasin_production/${selectedModel}`
          );
          setEntre(response.data.value || 0);
          setEncore(response.data.encore || 0);
          calculateEncore(response.data.value);
        } catch (error) {
          calculateEncore(0);
          console.error("Error fetching Magasin Production data:", error);
        }
      }
    };

    fetchMagasinProduction();
  }, [selectedModel]);

  const calculateEncore = async (magasinValue) => {
    try {
      const response = await api.get(
        `/control_production/${selectedModel}`
      );
      const totalSortie = response.data.value;
      setTotalSortie(totalSortie);

      const encore = totalSortie - magasinValue;
      if (encore < 0) {
        toast.error("Encore cannot be less than 0");
        setEncore(0);
      } else {
        setEncore(encore);
      }
    } catch (error) {
      console.error("Error calculating encore:", error);
    }
  };

  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const handleValueChange = async (name , value) => {
    const newValue = Number(value);

    if (newValue < 0) {
      toast.error("Value cannot be less than 0");
      return;
    }

    if (newValue > totalSortie) {
      toast.error("Total Repassage can't be more than the total sortie");
      return;
    }

    setProduction(newValue);
  };

  const handleSave = async () => {
    try {
      const response = await api.post(`/magasin_production/${selectedModel}`, {
        value: production,
      });

      const responsec = await api.get(
        `/control_production/${selectedModel}`
      );
      const totalSortie = responsec.data.value;

      setEncore(parseInt(totalSortie) - parseInt(response.data.value));
      setEntre(response.data.value)
      setProduction(0);
      toast.success("Record saved successfully");
    } catch (error) {
      console.error("Error saving production data:", error);
    }
  }

  const handleUpdate = async () => {
    try {
      const response = await api.post(`/magasin_production_update/${selectedModel}`, {
        value: production,
      });
      const responsec = await api.get(
        `/control_production/${selectedModel}`
      );
      const totalSortie = responsec.data.value;

      setEncore(parseInt(totalSortie) - parseInt(response.data.value));
      setEntre(response.data.value)
      setProduction(0);
      toast.success("Record Updated successfully");
    } catch (error) {
      console.error("Error saving production data:", error);
    }
  }


  const selectedModelData = models.find((model) => model.id == selectedModel);

  const handleReset = async () => {
    try {
      await api.delete(`/magasin_production/${selectedModel}`)
      const responsec = await api.get(
        `/control_production/${selectedModel}`
      );
      const totalSortie = responsec.data.value;

      setEncore(parseInt(totalSortie));
      setEntre(0)
    } catch (error) {
      console.error("Error saving production data:", error);
    }
  }

  return (
    <div className="ml-[19%] pt-[6rem]">
      <ToastContainer />
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">dépôt Final</h2>
        <div className="ml-7 mb-4 pr-6">
          <select
            className="block w-full mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
            value={selectedModel}
            onChange={handleModelChange}
          >
            <option value="">Select model</option>
            {models.map((model) => (
              <option key={model.id} value={model.id}>
                {model.modele}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedModelData && (
        <div className="w-full pr-6">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold text-xl">
              {selectedModelData.category}
            </h3>
            <h3 className="font-semibold text-xl">
              {selectedModelData.client}
            </h3>
          </div>
          <div className="flex items-center justify-between">
            <h3 className="font-semibold text-xl">Encore: {encore}</h3>
            <h3 className="font-semibold text-xl">Total: {entre}</h3>
          </div>
          <button
            onClick={handleReset}
            className="mt-4 bg-red-500 text-white p-2 rounded"
          >
            Reset
          </button>

        </div>
      )}
      {selectedModel && (
                         <div className="flex flex-col p-5 mt-[2rem] mb-4 shadow-md w-fit p-1 rounded border">
                         <Input
                           handleChange={handleValueChange}
                           name={"depot"}
                           label="Total dépôt Final"
                           text={production ? production.toString() : "0"}
                         />
                         <div className="flex items-center justify-between">
                         <button
                            onClick={handleSave}
                            className="mt-4 bg-blue-500 text-white p-2 rounded w-[48%]"
                            >
                            Save
                          </button>
                          <button
                            onClick={handleUpdate}
                            className="mt-4 bg-yellow-500 text-white p-2 rounded w-[48%]"
                            >
                            Modifier
                          </button>
                        </div>
                     </div>
      )}
    </div>
  );
};

export default MagasinProduction;
